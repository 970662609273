/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { motion } from "framer-motion-3d"

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/snowspeeder.glb");
  return (
    <motion.group ref={group} {...props} dispose={null}
    initial={{ opacity: 0, x: 10 }}
    animate={{ opacity: 1, x: 0 }}        
    transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <group position={[0, -6.27, 11.34]}>
        <group position={[0.32, 3.13, -5.67]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.canopy_glass.geometry}
            material={nodes.canopy_glass.material}
            position={[-0.8, 73.29, 13.1]}
            material-color="#3b3b3b"  
            material-roughness="0.2"
            material-clearcoat="0.1"  
            material-metalness="0.3"             
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.gun.geometry}
            material={nodes.gun.material}
            position={[0, 65.74, 180.02]}
            material-color="#76CBCC"  
            material-roughness="0.5" 
            material-metalness="0.8"              
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.main.geometry}
            material={nodes.main.material}
            position={[-0.32, 15.02, -9.79]}
            material-color="#76CBCC"
            material-roughness="0.5" 
            material-metalness="0.8"              
          />
        </group>
      </group>
    </motion.group>
  );
}

useGLTF.preload("/snowspeeder.glb");