import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import FeatherIcon from 'feather-icons-react';
import { motion } from 'framer-motion';
import { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, OrbitControls, MeshReflectorMaterial, Loader } from '@react-three/drei'
import Snowspeeder from '../components/snowspeeder'


function Model() {
  const ref = useRef()
  return (
    <Suspense fallback={null}>   

      <Snowspeeder scale={0.02} position={[0, 0.5, 0]} rotation={[0, Math.PI * 0.6, 0]}/>
       
  </Suspense>
  )
}

const SnowspeederPage = () => (
  
  <Layout>
    

    <Helmet>
      <body className="body-dark"></body>
    </Helmet>

<section className="h-screen flex items-end content-end">

      <motion.div 
        initial={{x: 50, opacity: 0}}      
        animate={{x: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }}       
        className="absolute p-5 sm:mb-5 z-50">  

        <a href="/work#play">
          <FeatherIcon icon="corner-up-left" size="36" stroke="#222" className="mb-6"/>   
        </a>        

        <h1 className="display-1 mb-2">Snowspeeder</h1>
        <motion.hr 
          className="mb-2"
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        /> 
        <p className="display-3">C4D // R3F // Click &amp; drag to rotate</p>
      </motion.div>  


      <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 20, 5], fov: 40 }}> 
      <fog attach="fog" args={['#cdcdcd', 0, 80]} />
      <color attach="background" args={['#cdcdcd']} />
          <Model  />  
          <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.2, 0]}>
          <planeGeometry args={[170, 170]} />
          <MeshReflectorMaterial
            blur={[400, 100]}
            resolution={1024}
            mirror={0.75}
            mixBlur={1}
            opacity={2}
            depthScale={1.1}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.25}
            roughness={0.5}
            reflectorOffset={0.5}
            color="#cdcdcd"
          />
        </mesh>   
        <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, -1.199, 0]}>
          <planeGeometry args={[100, 100]} />
          <shadowMaterial transparent color="black" opacity={0.4} />
        </mesh>
          <hemisphereLight intensity={0.5} />    
          <spotLight position={[10, 50, 0]} angle={0.25} penumbra={0.3} castShadow intensity={0.5} shadow-bias={-0.0001} />            
        <OrbitControls
        autoRotate
        autoRotateSpeed={0.6}
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2.2}
        maxPolarAngle={Math.PI / 2.2}
      />     
      </Canvas>
      <Loader />  
  </section>
 
  </Layout>
)

function Striplight({ color = 'white', emissiveIntensity = 4, ...props }) {
  return (
    <mesh {...props}>
      <planeGeometry />
      <meshStandardMaterial color={color} emissive={color} emissiveIntensity={emissiveIntensity} toneMapped={false} />
    </mesh>
  )
}

function Ringlight(props) {
  return (
    <mesh {...props}>
      <ringGeometry args={[1, 2, 64]} />
      <meshBasicMaterial color={[19, 10, 10]} toneMapped={false} />
    </mesh>
  )
}



export default SnowspeederPage
